/**
 * This file is part of VILLASweb.
 *
 * VILLASweb is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * VILLASweb is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with VILLASweb. If not, see <http://www.gnu.org/licenses/>.
 ******************************************************************************/

/**
 * Application container
 */

:root {
  --main: #4d4d4d;
  --background: #eceded;
  --highlights: #527984;
  --secondaryText: #818181;
  --borderRadius: 0px;
  --fontFamily: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  --backgroundText:#fff;
}

* {
  color: var(--main);
}

body {
  background-color: var(--background) !important;
  font-family: var(--fontFamily) !important;
}

.app {
  height: 100vh;
  color: var(--main);
  border-radius: var(--borderRadius);


  font: 16px;
  hyphens: auto;
}

.app-header {
  width: 100%;
  background-color: #fff;
}

.app-header h1 {
  padding: 5px 0;
  margin: 0;
  width: 100%;
  text-align: center;
  color: var(--highlights);
}

.btn-link {
  padding: 0 !important;
}

a:link { 
  text-decoration: none; 
}

a:visited { 
  text-decoration: none; 
}

a:hover { 
  text-decoration: none; 
}

a:active { 
  text-decoration: none; 
}


.funding-logos img {
  height: 70px;
  margin: 0 10px;
}

.app-footer {
  width: 100%;

  margin-top: 2em;
  padding-bottom: 2em;
  color: var(--backgroundText);
  text-align: center;
}

.app-footer a {
  text-decoration: underline;
  color: var(--backgroundText);
}

.app-body-spacing {
  padding: 20px;
}

.app-content-fullscreen {
  height: 100%;
}

.app-content {
  padding: 15px 20px 20px 20px;
  border-radius: var(--borderRadius);


  width: auto;
  min-height: 300px;

  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2),
              0 9px 18px 0 rgba(0, 0, 0, 0.1);
}

.app-content-margin-left {
  margin-left: 180px !important;
}

.verticalhorizontal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 800px;
}

/**
 * Menus
 */
.menucontainer {
  float: left;
  flex-direction: column;
}

.menu {
  padding: 20px;
  margin-bottom: 20px;
  width: 160px;
  border-radius: var(--borderRadius);


  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2),
              0 9px 18px 0 rgba(0, 0, 0, 0.1);
}

.menulogo {
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  margin-bottom: 20px;
  width: 160px;
  border-radius: var(--borderRadius);

  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2),
              0 9px 18px 0 rgba(0, 0, 0, 0.1);
}

.menu a {
  color: var(--secondarytext);
  text-decoration:none;
}

.menu a:hover, .menu a:focus {
  text-decoration:none;
}

.active {
  font-weight: bold;
}

.menu ul {
  padding-top: 10px;
  padding-left: 0px;
  margin-bottom: 0;
  list-style: none;
  white-space: nowrap;
}
.menu li {
  margin: 5px 0;
}

.menu a::after {
  /* Trick to make menu items to be as wide as in bold */
  display: block;
  content: attr(title);
  font-weight: bold;
  height: 1px;
  color: transparent;
  overflow: hidden;
  visibility: hidden;
  margin-bottom: -1px;
}

.sidenav {
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100;
  background-color: #111;
  overflow-x: hidden;
  padding-top: 60px;
  transition: 0.5s;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: var(--secondarytext);
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #f1f1f1;
  text-decoration: none;
}

.sidenav .closeButton {
  position: absolute;
  top: -15px;
  right: 15px;
  font-size: 56px;
  margin-left: 50px;
  text-decoration: none;
  display: block;
  color: var(--secondarytext);
  transition: 0.3s;
}

.sidenav .closeButton:hover {
  text-decoration: none;
  color: #f1f1f1;
}

/**
 * Home page
 */
.home-container > ul {
  margin-left: 2em;
  padding-left: 0px;
}

.home-container > img {
	margin: 20px;
}

/**
 * Dashboards
 */
.fullscreen-container {
  padding: 10px;
  background-color: white;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border:0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

/**
 * Tables
 */
.table thead,th,td {
  padding: 0.5em 0.5em !important;
}

.table thead,th {
  background-color: var(--highlights);
  color: #fff;
}

/**
 * Buttons
 */
.btn-table-control-button {
  padding: 0 0.5em !important;
  line-height: 1em !important;
}

.btn-table-control-button:hover {
  color: #888 !important;
}

.table-control-checkbox input {
  position: relative !important;
  margin-top: 0 !important;
}

.unselectable {
  -webkit-touch-callout: none !important; /* iOS Safari */
    -webkit-user-select: none !important; /* Safari */
     -khtml-user-select: none !important; /* Konqueror HTML */
       -moz-user-select: none !important; /* Firefox */
        -ms-user-select: none !important; /* Internet Explorer/Edge */
            user-select: none !important; /* Non-prefixed version, currently
                                             supported by Chrome and Opera */
}

/**
 * Toolbox
 */
.toolbox-dropzone {
  width: 100%;
  min-height: 400px;

  display: flex;
  position: relative;
}

.toolbox-dropzone-editing {
  outline: 3px dashed #e1e1e1;
}

.toolbox-dropzone-active {
  border-color: #aaa;
}

.toolbox {
  margin-top: 10px;
  margin-bottom: 10px;
}

.toolbox-item {
  display: inline-block;

  margin-right: 5px;
  cursor: move;
}

.toolbox-item-dragging {
  opacity: 0.4;
}

.toolbox-item-disabled {
  border-color: lightgray;

  color: lightgray;

  cursor: default !important;
}

/**
 *  Sections
 */

.box {
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
}

.box-header {
  -webkit-flex: 0 0 auto;
  flex: 0 0 auto;
}

.box-content {
  -webkit-flex: 1 0 auto;
  flex: 1 0 auto;
}

.section {
  min-height: 100%;
  width: 100%;
}

.section-header div {
  display: inline-block;
  vertical-align: middle;
  /* height: 100%; */
}

.section-title {
  font-size: 1.5em;
}

.section-header .section-buttons-group {
  margin-right: 10px;
  margin-left: 10px;
}

.section-header button {
  padding-left: 3px;
  padding-right: 3px;
}

.section-header .btn-link:hover, .section-header .btn-link:focus {
  text-decoration: none;
}

.section-buttons-group-right {
  height: auto !important;
  padding: 5px;
  float: right;
  border-color: #ffffff;
  background-color: #ffffff;
}

.section-buttons-group-center {
  height: auto !important;
  padding-left: 3px;
  padding-right: 13px;
  border-color: #ffffff;
  background-color: #ffffff;
}

.section-buttons-group-center .btn{
  border-color: #ffffff;
  background-color: #ffffff;
}

.section-buttons-group-center .btn:hover{
  border-color: #e3e3e3;
  background-color: #e3e3e3;
}

.section-buttons-group-right .btn{
  border-color: #ffffff;
  background-color: #ffffff;
}

.section-buttons-group-right .btn:hover{
  border-color: #e3e3e3;
  background-color: #e3e3e3;
}

.section-buttons-group-left {
  height: auto !important;
  padding: 5px;
  float: left;
}

.section-buttons-group-left .btn{
  background-color: var(--highlights);
  border-color: var(--highlights);
}

.section-buttons-group-left .btn:hover{
  background-color: #31484f;
  border-color: #31484f;
}

.drag-and-drop .btn{
  color: var(--highlights);
  border-color: var(--highlights);
}

.drag-and-drop .btn:hover{
  color: var(--highlights);
  border-color: var(--highlights);
}


.section-buttons-group-right .rc-slider {
  margin-left: 12px;
}

.solid-button .btn{
  background-color: var(--highlights);
  border-color: var(--highlights);
}

.solid-button .btn:hover{
  background-color: #31484f; 
  border-color: #31484f;
}

.solid-button .btn:disabled{
  background-color: var(--highlights);
  border-color: var(--highlights);
}

.icon-button .btn{
  border-color: #ffffff;
  background-color: #ffffff;
}

.icon-button .btn:hover{
  border-color: #e3e3e3;
  background-color: #e3e3e3;
}

.icon-color {
  color: var(--highlights);
}

.form-horizontal .form-group {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.badge-outdated {
  opacity: 0.4;
}


/**
 * Swagger UI
 */
.swagger-ui .wrapper {
	padding: 0 !important;
}

.swagger-ui .info {
  margin-top: 10px;
}

.table thead th {
  background-color: var(--highlights);
  color: #fff;
}
