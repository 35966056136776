/**
 * This file is part of VILLASweb.
 *
 * VILLASweb is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * VILLASweb is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with VILLASweb. If not, see <http://www.gnu.org/licenses/>.
 ******************************************************************************/

.login-parent {
    display: flex;
    max-width: 800px;
    margin: 30px auto;
}

.login-welcome {
    float: right;
    max-width: 400px;
    padding: 15px 20px;
    border-radius: var(--borderradius) 0px 0px var(--borderradius); 
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2),
                0 9px 18px 0 rgba(0, 0, 0, 0.1);
}

.login-container {
    float: left;
    max-width: 400px;
    border-radius: 0px var(--borderradius) var(--borderradius) 0px;
    padding: 15px 20px; 
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2),
                0 9px 18px 0 rgba(0, 0, 0, 0.1);
}